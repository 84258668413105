<template>
  <div>
    <MainStepper e1="3" />
    <v-tabs v-model="tabs" fixed-tab color="primary">
      <v-tabs-slider></v-tabs-slider>
      <v-divider vertical></v-divider>
      <v-tab
        href="#mobile-tabs-5-1"
        class="primary--text"
        active-class="grey lighten-2"
      >
        <v-icon class="primary--text mr-2">mdi-account-check-outline</v-icon>
        Approved Suppliers List
      </v-tab>
      <v-divider vertical></v-divider>
      <v-tab
        href="#mobile-tabs-5-2"
        class="primary--text"
        active-class="grey lighten-2"
      >
        <v-icon class="primary--text mr-2">mdi-briefcase-check-outline</v-icon>
        RFP/RFQ (Public Domain)
      </v-tab>
      <v-divider vertical></v-divider>
      <v-tab
        href="#mobile-tabs-5-3"
        class="primary--text"
        active-class="grey lighten-2"
      >
        <v-icon class="primary--text mr-2">mdi-account-check-outline</v-icon>
        Single Sourcing
      </v-tab>
      <v-divider vertical></v-divider>
      <v-spacer></v-spacer>
      <div class="my-auto mr-2">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="text-capitalize ml-1 float-right"
              :loading="loading"
              @click="gotToAuthorityApprovals"
              elevation="0"
              fab
              x-small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </template>
          <span>Skip to approvals</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="text-capitalize ml-1 float-right"
              :loading="loading"
              @click="gotToPreApproval"
              elevation="0"
              fab
              x-small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </template>
          <span>Back</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="text-capitalize ml-1 float-right"
              :loading="loading"
              @click="gotToSupplierPrequalification"
              elevation="0"
              fab
              x-small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-account-multiple-check-outline</v-icon>
            </v-btn>
          </template>
          <span>Supplier applications</span>
        </v-tooltip>
      </div>
    </v-tabs>
    <v-divider></v-divider>
    <v-tabs-items v-model="tabs">
      <!-- Prequalified Suppliers list  -->
      <v-tab-item value="mobile-tabs-5-1">
        <v-data-table
          :headers="headers"
          :items="items"
          sort-by="title"
          class="elevation-1"
          loading-text="Loading... Please wait"
          :search="search"
          :custom-filter="filterText"
        >
          <template v-slot:top>
            <v-toolbar flat class="pt-2">
              <v-row>
                <v-col cols="6">
                  <span class="text-h6 primary--text">SUPPLIER CATEGORIES</span>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="search..."
                    v-model="search"
                    append-icon="mdi-magnify"
                    dense
                    width="54"
                    color="primary"
                    class="float-right"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-toolbar>
            <v-progress-linear
              indeterminate
              color="primary"
              v-if="loading"
            ></v-progress-linear>
            <v-divider></v-divider>
          </template>
          <template v-slot:[`item.title`]="{ item }">
            <div class="my-1">
              <a
                color="primary"
                class="
                  mx-1
                  primary--text
                  text--darken-1 text-decoration-underline
                "
                small
                dark
                @click="viewItem(item)"
              >
                {{ truncateText(item.title, 50) }}
              </a>
            </div>
          </template>

          <template v-slot:[`item.description`]="{ item }">
            <div class="my-1">
              {{ truncateText(item.description, 80) }}
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="my-1">
              <a
                color="primary"
                class="
                  mx-1
                  primary--text
                  text--darken-1 text-decoration-underline
                "
                small
                dark
                @click="viewItem(item)"
              >
                View Suppliers
              </a>
            </div>
          </template>
        </v-data-table>
      </v-tab-item>
      <!-- Public domain  -->
      <v-tab-item value="mobile-tabs-5-2">
        <v-data-table
          :headers="headers_tenders"
          :items="tenders"
          sort-by="name"
          class="elevation-1"
          :search="searchTender"
          :custom-filter="filterTender"
        >
          <template v-slot:top>
            <v-toolbar flat class="pt-2">
              <v-col cols="6">
                <span class="text-h6 primary--text">LIST OF ALL TENDERS</span>
              </v-col>
              <v-col cols="6">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      class="text-capitalize float-right ml-1"
                      :loading="loading"
                      @click="goToEvaluation"
                      elevation="0"
                      fab
                      x-small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-book-edit</v-icon>
                    </v-btn>
                  </template>
                  <span>Evaluations</span>
                </v-tooltip>
                <v-dialog
                  v-model="dialog"
                  scrollable
                  max-width="950"
                  persistent
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      class="text-capitalize ml-1 float-right"
                      :loading="loading"
                      elevation="0"
                      fab
                      x-small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="error--text text--darken-1"
                      >{{ formTitle }}
                      <v-spacer></v-spacer>
                      <v-btn
                        color="error"
                        class=""
                        dark
                        fab
                        elevation="0"
                        x-small
                        @click="close"
                      >
                        <v-icon small>mdi-window-close</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text style="height: 500px">
                      <v-container>
                        <v-form ref="form" v-model="valid">
                          <v-row>
                            <v-col cols="12" sm="4" md="4">
                              <v-text-field
                                v-model="editedItem.tender_no"
                                label="Tender Number"
                                :rules="rules.required"
                                color="grey"
                                dense
                                outlined
                                class="mb-n3"
                              >
                                <template v-slot:label>
                                  <span class="input__label"
                                    >Tender Number
                                    <v-icon small color="error" class="mt-n2"
                                      >mdi-star-outline</v-icon
                                    ></span
                                  >
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="8" md="8" dense>
                              <v-text-field
                                v-model="editedItem.title"
                                label="Tender Title"
                                :rules="rules.required"
                                color="grey"
                                dense
                                outlined
                                class="mb-n3"
                              >
                                <template v-slot:label>
                                  <span class="input__label"
                                    >Tender Title
                                    <v-icon small color="error" class="mt-n2"
                                      >mdi-star-outline</v-icon
                                    ></span
                                  >
                                </template>
                              </v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="12" class="my-n5">
                              <v-textarea
                                v-model="editedItem.description"
                                outlined
                                name="input-7-4"
                                label="Tender Details"
                                color="grey"
                                dense
                                :rules="rules.required"
                                class="mb-n3"
                              >
                                <template v-slot:label>
                                  <span class="input__label"
                                    >Tender Details
                                    <v-icon small color="error" class="mt-n2"
                                      >mdi-star-outline</v-icon
                                    ></span
                                  >
                                </template>
                              </v-textarea>
                            </v-col>

                            <v-col cols="12" sm="4" md="4">
                              <v-select
                                v-model="editedItem.category"
                                :items="tenderCategories"
                                label="Select Category"
                                item-text="title"
                                item-value="id"
                                color="grey"
                                dense
                                outlined
                                :rules="rules.select"
                              >
                                <template v-slot:label>
                                  <span class="input__label"
                                    >Tender Category
                                    <v-icon small color="error" class="mt-n2"
                                      >mdi-star-outline</v-icon
                                    ></span
                                  >
                                </template>
                              </v-select>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                              <v-select
                                v-model="editedItem.application_steps"
                                :items="tender_steps"
                                label="Application Steps"
                                color="grey"
                                item-value="id"
                                item-text="name"
                                dense
                                outlined
                                :rules="rules.select"
                              >
                                <template v-slot:label>
                                  <span class="input__label"
                                    >Application Steps
                                    <v-icon small color="error" class="mt-n2"
                                      >mdi-star-outline</v-icon
                                    ></span
                                  >
                                </template>
                              </v-select>
                            </v-col>

                            <v-col cols="12" sm="4" md="4">
                              <v-text-field
                                v-model="editedItem.budget"
                                label="Budget"
                                outlined
                                dense
                                color="grey"
                              >
                              </v-text-field>
                            </v-col>

                            <v-col cols="12" sm="4" md="4">
                              <v-text-field
                                v-model="editedItem.application_fee"
                                label="Application Fee"
                                outlined
                                dense
                                color="grey"
                              ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="4" md="4">
                              <v-select
                                v-model="editedItem.status"
                                :items="tender_status"
                                label="Status"
                                color="grey"
                                dense
                                outlined
                                :rules="rules.select"
                                item-value="id"
                                item-text="name"
                              >
                                <template v-slot:label>
                                  <span class="input__label"
                                    >Tender Status
                                    <v-icon small color="error" class="mt-n2"
                                      >mdi-star-outline</v-icon
                                    ></span
                                  >
                                </template></v-select
                              >
                            </v-col>
                            <!-- Closing date  -->
                            <v-col cols="12" sm="4" md="4">
                              <v-menu
                                ref="menu_closing_date"
                                v-model="menu_closing_date"
                                :close-on-content-click="true"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="editedItem.closing_date"
                                    :value="computedClosingDate"
                                    label="Closing Date"
                                    persistent-hint
                                    append-icon="mdi-calendar"
                                    v-bind="attrs"
                                    v-on="on"
                                    outlined
                                    dense
                                    color="grey"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="closingDateWatcher"
                                  no-title
                                  @change="menu_closing_date = false"
                                ></v-date-picker>
                              </v-menu>
                            </v-col>
                            <!-- Clarification end date  -->
                            <v-col cols="12" sm="4" md="4">
                              <v-menu
                                ref="menu_clarification_date"
                                v-model="menu_clarification_date"
                                :close-on-content-click="true"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="editedItem.clarification_end_date"
                                    :value="computedClarificationDate"
                                    label="Clarification End Date"
                                    persistent-hint
                                    append-icon="mdi-calendar"
                                    v-bind="attrs"
                                    v-on="on"
                                    outlined
                                    dense
                                    color="grey"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="clarificationDateWatcher"
                                  no-title
                                  @change="menu_clarification_date = false"
                                ></v-date-picker>
                              </v-menu>
                            </v-col>
                            <!-- Evaluation end date  -->
                            <v-col cols="12" sm="4" md="4">
                              <v-menu
                                ref="menu_clarification_date"
                                v-model="menu_evaluation_date"
                                :close-on-content-click="true"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="editedItem.evaluation_end_date"
                                    :value="computedEvaluationDate"
                                    label="Evaluation End Date"
                                    persistent-hint
                                    append-icon="mdi-calendar"
                                    v-bind="attrs"
                                    v-on="on"
                                    outlined
                                    dense
                                    color="grey"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="evaluationDateWatcher"
                                  no-title
                                  @change="menu_evaluation_date = false"
                                ></v-date-picker>
                              </v-menu>
                            </v-col>

                            <!-- Approval end date  -->
                            <v-col cols="12" sm="4" md="4">
                              <v-menu
                                ref="menu_approval_date"
                                v-model="menu_approval_date"
                                :close-on-content-click="true"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="editedItem.tender_approval_date"
                                    :value="computedApprovalDate"
                                    label="Tender Approval Date"
                                    persistent-hint
                                    append-icon="mdi-calendar"
                                    v-bind="attrs"
                                    v-on="on"
                                    outlined
                                    dense
                                    color="grey"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="approvalDateWatcher"
                                  no-title
                                  @change="menu_approval_date = false"
                                ></v-date-picker>
                              </v-menu>
                            </v-col>

                            <!-- required docs  -->
                            <v-col cols="12" sm="12" md="12" class="mt-n5">
                              <v-select
                                v-model="editedItem.required_documents"
                                :value="editedItem.required_documents"
                                :rules="rules.select2"
                                :items="required_documents"
                                label="Mandatory Documents"
                                item-text="name"
                                item-value="id"
                                multiple
                                outlined
                                chips
                                color="grey"
                                item-color="grey"
                                class="my-1 mx-1"
                              >
                                <template v-slot:label>
                                  <span class="input__label"
                                    >Mandatory Documents
                                    <v-icon small color="error" class="mt-n2"
                                      >mdi-star-outline</v-icon
                                    ></span
                                  >
                                </template>
                                <template v-slot:prepend-item>
                                  <v-list-item ripple @click="toggleRequired">
                                    <v-list-item-action>
                                      <v-icon
                                        :color="
                                          editedItem.required_documents.length >
                                          0
                                            ? 'error'
                                            : ''
                                        "
                                      >
                                        {{ iconRequired }}
                                      </v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        Select All
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-divider class="mt-2"></v-divider>
                                </template>
                                <template v-slot:append-item>
                                  <v-divider class="mb-2"></v-divider>
                                  <v-list-item disabled>
                                    <v-list-item-avatar color="grey lighten-3">
                                      <v-icon> mdi-file-outline </v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content
                                      v-if="likesAllRequiredDocument"
                                    >
                                      <v-list-item-title>
                                        All Documents selected for mandatory
                                        fields!
                                      </v-list-item-title>
                                    </v-list-item-content>

                                    <v-list-item-content
                                      v-else-if="likesSomerequired_documents"
                                    >
                                      <v-list-item-title>
                                        Document Count
                                      </v-list-item-title>
                                      <v-list-item-subtitle>
                                        {{
                                          editedItem.required_documents.length
                                        }}
                                      </v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-content v-else>
                                      <v-list-item-title>
                                        Which documents are mandatory for this
                                        tender?
                                      </v-list-item-title>
                                      <v-list-item-subtitle>
                                        Go ahead, make a selection above!
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>
                              </v-select>
                            </v-col>

                            <!-- technical docs -->
                            <v-col cols="12" sm="12" md="12" class="mt-n5">
                              <v-select
                                v-model="editedItem.technical_documents"
                                :value="editedItem.technical_documents"
                                :rules="rules.select2"
                                :items="technical_documents"
                                label="Technical Documents"
                                item-text="name"
                                item-value="id"
                                multiple
                                outlined
                                chips
                                color="grey"
                                item-color="grey"
                                class="my-1 mx-1"
                              >
                                <template v-slot:label>
                                  <span class="input__label"
                                    >Technical Documents
                                    <v-icon small color="error" class="mt-n2"
                                      >mdi-star-outline</v-icon
                                    ></span
                                  >
                                </template>
                                <template v-slot:prepend-item>
                                  <v-list-item ripple @click="toggleTechnical">
                                    <v-list-item-action>
                                      <v-icon
                                        :color="
                                          editedItem.technical_documents
                                            .length > 0
                                            ? 'error'
                                            : ''
                                        "
                                      >
                                        {{ iconTechnical }}
                                      </v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        Select All
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-divider class="mt-2"></v-divider>
                                </template>
                                <template v-slot:append-item>
                                  <v-divider class="mb-2"></v-divider>
                                  <v-list-item disabled>
                                    <v-list-item-avatar color="grey lighten-3">
                                      <v-icon> mdi-file-outline </v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content
                                      v-if="likesAllTechnicalDocument"
                                    >
                                      <v-list-item-title>
                                        All Documents selected for technical
                                        fields!
                                      </v-list-item-title>
                                    </v-list-item-content>

                                    <v-list-item-content
                                      v-else-if="likesSometechnical_documents"
                                    >
                                      <v-list-item-title>
                                        Document Count
                                      </v-list-item-title>
                                      <v-list-item-subtitle>
                                        {{
                                          editedItem.technical_documents.length
                                        }}
                                      </v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-content v-else>
                                      <v-list-item-title>
                                        Which documents are technical for this
                                        tender?
                                      </v-list-item-title>
                                      <v-list-item-subtitle>
                                        Go ahead, make a selection above!
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>
                              </v-select>
                            </v-col>

                            <!-- financial docs -->
                            <v-col cols="12" sm="12" md="12" class="mt-n5">
                              <v-select
                                v-model="editedItem.financial_documents"
                                :value="editedItem.financial_documents"
                                :rules="rules.select2"
                                :items="financial_documents"
                                label="Financial Documents"
                                item-text="name"
                                item-value="id"
                                multiple
                                outlined
                                chips
                                color="grey"
                                item-color="grey"
                                class="my-1 mx-1"
                              >
                                <template v-slot:label>
                                  <span class="input__label">
                                    Financial Documents
                                    <v-icon small color="error" class="mt-n2"
                                      >mdi-star-outline</v-icon
                                    ></span
                                  >
                                </template>
                                <template v-slot:prepend-item>
                                  <v-list-item ripple @click="toggleFinancial">
                                    <v-list-item-action>
                                      <v-icon
                                        :color="
                                          editedItem.financial_documents
                                            .length > 0
                                            ? 'error'
                                            : ''
                                        "
                                      >
                                        {{ iconFinancial }}
                                      </v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        Select All
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-divider class="mt-2"></v-divider>
                                </template>
                                <template v-slot:append-item>
                                  <v-divider class="mb-2"></v-divider>
                                  <v-list-item disabled>
                                    <v-list-item-avatar color="grey lighten-3">
                                      <v-icon> mdi-file-outline </v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content
                                      v-if="likesAllFinancialDocument"
                                    >
                                      <v-list-item-title>
                                        All Documents selected for financial
                                        fields!
                                      </v-list-item-title>
                                    </v-list-item-content>

                                    <v-list-item-content
                                      v-else-if="likesSomefinancial_documents"
                                    >
                                      <v-list-item-title>
                                        Document Count
                                      </v-list-item-title>
                                      <v-list-item-subtitle>
                                        {{
                                          editedItem.financial_documents.length
                                        }}
                                      </v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-content v-else>
                                      <v-list-item-title>
                                        Which documents are financial for this
                                        tender?
                                      </v-list-item-title>
                                      <v-list-item-subtitle>
                                        Go ahead, make a selection above!
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>
                              </v-select>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-container>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        dark
                        class="text-capitalize mx-1"
                        :loading="loading"
                        @click="saveTender"
                        elevation="0"
                      >
                        Submit <v-icon>mdi-content-save-outline</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-text-field
                  label="search..."
                  v-model="searchTender"
                  append-icon="mdi-magnify"
                  dense
                  width="54"
                  color="primary"
                  class="float-right"
                ></v-text-field>
              </v-col>
            </v-toolbar>
            <v-progress-linear
              indeterminate
              color="primary"
              v-if="loading"
            ></v-progress-linear>
            <v-divider></v-divider>
          </template>

          <template v-slot:[`item.closing_date`]="{ item }">
            {{ item.closing_date }}
          </template>
          <template v-slot:[`item.documents`]="{ item }">
            {{ item.name }}
            <a
              v-for="(item, i) in item.documents"
              :key="i"
              link
              v-bind:href="docURI"
              >({{ i + 1 }}) {{ item.name }} ,
            </a>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <a v-if="item.status.slug == 'open'" class="success--text">
              {{ item.status.name }}
            </a>
            <a v-else class="grey--text">{{ item.status.name }}</a>
          </template>
          <template v-slot:[`item.company`]="{ item }">
            {{ getCompanyName(item.company) }}
          </template>
          <template v-slot:[`item.tender_no`]="{ item }">
            <a
              @click.stop="tenderPreview(item)"
              class="primary--text text--darken-1 text-decoration-underline"
              >{{ item.tender_no }}</a
            >
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              class="mx-1"
              color="alt"
              fab
            depressed
              x-small
              dark
              @click="editTender(item)"
            >
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>

            <v-btn
              class="mx-1"
              color="error"
              fab
            depressed
              x-small
              dark
              @click="deleteTender(item)"
            >
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.contractor_id`]="{ item }">
            <v-btn
              v-if="item.contractor_id && item.status.slug == 'closed'"
              class="text-capitalize"
              color="success"
              small
              elevation="0"
              dark
              @click="selectSupplier(item.contractor)"
            >
              <!-- samdoh  -->
              Awarded <v-icon small>mdi-chevron-right</v-icon>
            </v-btn>
            <v-btn
              v-else-if="!item.contractor_id && item.status.slug == 'closed'"
              class="text-capitalize"
              color="alt"
              small
              elevation="0"
              dark
            >
              <!-- samdoh  -->
              In progress
            </v-btn>
            <v-btn
              v-else
              class="text-capitalize"
              color="info"
              small
              elevation="0"
              dark
            >
              Pending...
              <!-- samdoh  -->
            </v-btn>
          </template>

          <template v-slot:[`item.bidders_count`]="{ item }">
            <v-btn
              class="mx-auto"
              :elevation="0"
              dark
              small
              rounded
              color="primary"
            >
              <span class="font-weight-bold button">
                {{ item.bidders_count }}</span
              >
            </v-btn>
          </template>

          <template v-slot:[`item.clarifications_count`]="{ item }">
            <v-btn
              v-if="item.clarifications_count > 0"
              class="mx-auto"
              :elevation="0"
              dark
              small
              rounded
              color="warning"
              @click="clarificationPreview(item)"
            >
              <span class="font-weight-bold button">
                {{
                  item.clarifications_answered + "/" + item.clarifications_count
                }}</span
              >
            </v-btn>
            <v-btn
              v-else
              class="mx-auto"
              :elevation="0"
              dark
              small
              rounded
              color="warning"
              @click="emptyClarificationPreview(item)"
            >
              <span class="font-weight-bold button">
                {{
                  item.clarifications_answered + "/" + item.clarifications_count
                }}</span
              >
            </v-btn>
          </template>
        </v-data-table>
      </v-tab-item>

      <!-- Single sourcing Suppliers list  -->
      <v-tab-item value="mobile-tabs-5-3">
        <v-data-table
          :headers="headers"
          :items="items"
          sort-by="title"
          class="elevation-1"
          loading-text="Loading... Please wait"
          :search="search"
          :custom-filter="filterText"
        >
          <template v-slot:top>
            <v-toolbar flat class="pt-2">
              <v-row>
                <v-col cols="6">
                  <span class="text-h6 primary--text">SUPPLIER CATEGORIES</span>
                </v-col>
                <v-col cols="6">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        dark
                        class="text-capitalize ml-1 float-right"
                        :loading="loading"
                        @click="newSupplierModal"
                        elevation="0"
                        fab
                        x-small
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-account-plus-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Add new supplier</span>
                  </v-tooltip>
                  <v-text-field
                    label="search..."
                    v-model="search"
                    append-icon="mdi-magnify"
                    dense
                    width="54"
                    color="primary"
                    class="float-right"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-toolbar>
            <v-progress-linear
              indeterminate
              color="primary"
              v-if="loading"
            ></v-progress-linear>
            <v-divider></v-divider>
          </template>
          <template v-slot:[`item.title`]="{ item }">
            <div class="my-1">
              <a
                color="primary"
                class="
                  mx-1
                  primary--text
                  text--darken-1 text-decoration-underline
                "
                small
                dark
                @click="viewItem(item)"
              >
                {{ truncateText(item.title, 50) }}
              </a>
            </div>
          </template>

          <template v-slot:[`item.description`]="{ item }">
            <div class="my-1">
              {{ truncateText(item.description, 80) }}
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="my-1">
              <a
                color="primary"
                class="
                  mx-1
                  primary--text
                  text--darken-1 text-decoration-underline
                "
                small
                dark
                @click="viewItem(item)"
              >
                View Suppliers
              </a>
            </div>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>

    <!-- Supplier modal -->
    <div class="text-center">
      <v-dialog v-model="supplier_modal" max-width="550px">
        <v-card>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-title>
              <span class="headline error--text text--darken-1"
                >Suppliers invitation</span
              >
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                class="text-capitalize mx-1"
                dark
                depressed
                fab
                x-small
                @click="close"
              >
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12" class="my-n2">
                    <v-text-field
                      v-model="editedItem.title"
                      label="Title"
                      :rules="rules.required"
                      color="grey"
                      dense
                      outlined
                      class="mb-n3"
                    >
                      <template v-slot:label>
                        <span class="input__label"
                          >Name
                          <v-icon small color="error" class="mt-n2"
                            >mdi-star-outline</v-icon
                          ></span
                        >
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="my-n2">
                    <v-text-field
                      v-model="editedItem.email"
                      label="Email"
                      :rules="rules.required"
                      color="grey"
                      dense
                      outlined
                      class="mb-n3"
                    >
                      <template v-slot:label>
                        <span class="input__label"
                          >Email address
                          <v-icon small color="error" class="mt-n2"
                            >mdi-star-outline</v-icon
                          ></span
                        >
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="my-n2">
                    <v-text-field
                      v-model="editedItem.phone"
                      label="Email"
                      :rules="rules.required"
                      color="grey"
                      dense
                      outlined
                      class="mb-n3"
                    >
                      <template v-slot:label>
                        <span class="input__label"
                          >Phone<v-icon small color="error" class="mt-n2"
                            >mdi-star-outline</v-icon
                          ></span
                        >
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                depressed
                dark
                class="text-capitalize mx-1"
                :loading="loading"
                @click="inviteSupplier"
              >
                Submit <v-icon>mdi-content-save-outline</v-icon>
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </div>

    <v-dialog v-model="dialog_select" max-width="550px">
      <v-card>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-title>
            <span class="headline primary--text">Supplier selection</span>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              class="text-capitalize mx-1"
              dark
              fab
              x-small
              elevation="0"
              @click="dialog_select = false"
            >
              <v-icon small>mdi-cancel</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider class="my-n2"></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12" class="my-n2">
                  <v-text-field
                    v-model="editedItem.name"
                    label="Reason/Justification *"
                    :rules="rules.required"
                    color="grey"
                    dense
                    outlined
                    class="mb-n3"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="my-n5">
                  <v-textarea
                    v-model="editedItem.description"
                    outlined
                    name="input-7-4"
                    label="Details"
                    color="grey"
                    dense
                    :rules="rules.required"
                    class="mb-n3"
                  >
                    <template v-slot:label>
                      <span class="input__label"
                        >Document Description
                        <v-icon small color="error" class="mt-n2"
                          >mdi-star-outline</v-icon
                        ></span
                      >
                    </template>
                  </v-textarea>
                </v-col>
                <v-col cols="12" class="my-n1">
                  <v-file-input
                    v-model="editedItem.attachment_file"
                    color="grey"
                    append-icon="mdi-attachment"
                    prepend-icon=""
                    label="Support Document"
                    outlined
                    dense
                    @change="handleFileUpload"
                  >
                  </v-file-input>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dark
              class="text-capitalize mx-1"
              :loading="loading"
              @click="continueToApproval"
            >
              Submit <v-icon>mdi-content-save-outline</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_select" max-width="550px">
      <v-card>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-title>
            <span class="headline primary--text">Supplier selection</span>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              class="text-capitalize mx-1"
              dark
              fab
              x-small
              elevation="0"
              @click="dialog_select = false"
            >
              <v-icon small>mdi-cancel</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider class="my-n2"></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12" class="my-n2">
                  <v-text-field
                    v-model="editedItem.name"
                    label="Reason/Justification *"
                    :rules="rules.required"
                    color="grey"
                    dense
                    outlined
                    class="mb-n3"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="my-n5">
                  <v-textarea
                    v-model="editedItem.description"
                    outlined
                    name="input-7-4"
                    label="Details"
                    color="grey"
                    dense
                    :rules="rules.required"
                    class="mb-n3"
                  >
                    <template v-slot:label>
                      <span class="input__label"
                        >Document Description
                        <v-icon small color="error" class="mt-n2"
                          >mdi-star-outline</v-icon
                        ></span
                      >
                    </template>
                  </v-textarea>
                </v-col>
                <v-col cols="12" class="my-n1">
                  <v-file-input
                    v-model="editedItem.attachment_file"
                    color="grey"
                    append-icon="mdi-attachment"
                    prepend-icon=""
                    label="Support Document"
                    outlined
                    dense
                    @change="handleFileUpload"
                  >
                  </v-file-input>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dark
              class="text-capitalize mx-1"
              :loading="loading"
              @click="continueToApproval"
            >
              Submit <v-icon>mdi-content-save-outline</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import SupplierCategoryService from "../../services/supplier_category.service";
import TenderService from "../../services/tender.service";
import User from "../../models/user";
import MainStepper from "../../components/MainStepper.vue";
import moment from "moment";

export default {
  components: { MainStepper },
  mounted() {
    this.tabs = "#mobile-tabs-5-1";
  },
  data: (vm) => ({
    tabs: null,
    name: "SupplierSelection",
    items: [],
    tenders: [],
    dialog_select: false,
    supplier_modal: false,
    required_documents: [],
    technical_documents: [],
    financial_documents: [],
    editedIndex: -1,
    loading: true,
    closingDateWatcher: new Date().toISOString().substr(0, 10),
    menu_closing_date: false,
    clarificationDateWatcher: new Date().toISOString().substr(0, 10),
    menu_clarification_date: false,
    evaluationDateWatcher: new Date().toISOString().substr(0, 10),
    menu_evaluation_date: false,
    approvalDateWatcher: new Date().toISOString().substr(0, 10),
    menu_approval_date: false,
    rules: {
      select: [(v) => !!v || "An item should be selected"],
      select2: [(v) => v.length > 0 || "Atleast one item should be selected"],
      required: [
        (v) => !!v || "Field is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
      file: [
        (v) => !!v || "File is required",
        (v) => (v && v.length > 0) || "File is required",
      ],
    },
    headers: [
      {
        text: "#",
        align: "start",
        filterable: true,
        value: "id",
      },
      {
        text: "Supplier Category",
        value: "title",
      },
      {
        text: "Supplier Category",
        value: "title",
      },
      {
        text: "Description",
        value: "description",
      },
    ],
    editedItem: {
      tender_no:
        "ABC/" +
        Math.floor(Math.random() * 9999 + 1000) +
        "/" +
        moment().format("yyyy"),
      title: "",
      description: "",
      category: "",
      budget: "",
      application_fee: "",
      application_steps: "",
      closing_date: vm.formatDate(new Date().toISOString().substr(0, 10)),
      clarification_end_date: vm.formatDate(
        new Date().toISOString().substr(0, 10)
      ),
      evaluation_end_date: vm.formatDate(
        new Date().toISOString().substr(0, 10)
      ),
      tender_approval_date: vm.formatDate(
        new Date().toISOString().substr(0, 10)
      ),
      status: "",
      required_documents: [],
      technical_documents: [],
      financial_documents: [],
      required_document_ids: [],
      technical_document_ids: [],
      financial_document_ids: [],
    },
    defaultItem: {
      tender_no:
        "ABC/" +
        Math.floor(Math.random() * 9999 + 1000) +
        "/" +
        moment().format("yyyy"),
      title: "",
      description: "",
      category: "",
      budget: "",
      application_fee: "",
      application_steps: "",
      closing_date: vm.formatDate(new Date().toISOString().substr(0, 10)),
      clarification_end_date: vm.formatDate(
        new Date().toISOString().substr(0, 10)
      ),
      evaluation_end_date: vm.formatDate(
        new Date().toISOString().substr(0, 10)
      ),
      tender_approval_date: vm.formatDate(
        new Date().toISOString().substr(0, 10)
      ),
      status: "",
      required_documents: [],
      technical_documents: [],
      financial_documents: [],
      required_document_ids: [],
      technical_document_ids: [],
      financial_document_ids: [],
    },

    tender_steps: [
      {
        id: 1,
        name: "One step",
      },
      {
        id: 2,
        name: "Two step",
      },
      {
        id: 3,
        name: "Three step",
      },
    ],

    tender_status: [
      {
        id: 1,
        name: "Draft",
      },
      {
        id: 2,
        name: "Open",
      },
      {
        id: 3,
        name: "Closed",
      },
      {
        id: 4,
        name: "Evaluated",
      },
    ],

    selected: [0],
    search: "",
    searchTender: "",
    files: "",
    docURI:
      "https://portal.ufaa.go.ke/frontend/web/index.php/page/download-all?tenderID=37",
    tenderCategories: [],
    user: new User("", ""),
    remember: 0,
    timeout: 2000,
    dialog: false,
    countries: ["Kenya", "Uganda", "Tanzania", "Rwanda"],
    owners: [1, 2, 3, 4],
    valid: true,
    headers_tenders: [
      {
        text: "Tender No",
        align: "start",
        filterable: true,
        value: "tender_no",
      },
      {
        text: "Tender Details",
        value: "title",
      },
      {
        text: "Category",
        value: "category.title",
      },
      {
        text: "Closing Date",
        value: "closing_date",
      },
      {
        text: "Closing Status",
        value: "status",
      },
      {
        text: "Clarifications",
        value: "clarifications_count",
      },
      {
        text: "Bidders",
        value: "bidders_count",
      },

      {
        text: "Evaluation Status",
        align: "center",
        value: "contractor_id",
        sortable: false,
        width: "10%",
      },
      {
        text: "Actions",
        align: "right",
        value: "actions",
        sortable: false,
        width: "10%",
      },
    ],
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New" : "Edit";
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    getLoading() {
      return this.loading;
    },
    computedClosingDate() {
      return this.formatDate(this.editedItem.closing_date);
    },
    computedClarificationDate() {
      return this.formatDate(this.editedItem.clarification_end_date);
    },
    computedEvaluationDate() {
      return this.formatDate(this.editedItem.evaluation_end_date);
    },
    computedApprovalDate() {
      return this.formatDate(this.editedItem.tender_approval_date);
    },
    // required docs
    likesAllRequiredDocument() {
      return (
        this.editedItem.required_documents.length ===
        this.required_documents.length
      );
    },
    likesSomerequired_documents() {
      return (
        this.editedItem.required_documents.length > 0 &&
        !this.likesAllRequiredDocument
      );
    },
    iconRequired() {
      if (this.likesAllRequiredDocument) return "mdi-close-box";
      if (this.likesSomerequired_documents) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    // technical docs
    likesAllTechnicalDocument() {
      return (
        this.editedItem.technical_documents.length ===
        this.technical_documents.length
      );
    },
    likesSometechnical_documents() {
      return (
        this.editedItem.technical_documents.length > 0 &&
        !this.likesAllTechnicalDocument
      );
    },
    iconTechnical() {
      if (this.likesAllTechnicalDocument) return "mdi-close-box";
      if (this.likesSometechnical_documents) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    // financial docs
    likesAllFinancialDocument() {
      return (
        this.editedItem.financial_documents.length ===
        this.financial_documents.length
      );
    },
    likesSomefinancial_documents() {
      return (
        this.editedItem.financial_documents.length > 0 &&
        !this.likesAllFinancialDocument
      );
    },
    iconFinancial() {
      if (this.likesAllFinancialDocument) return "mdi-close-box";
      if (this.likesSomefinancial_documents) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    clarificationDateWatcher() {
      this.editedItem.clarification_end_date = this.formatDate(
        this.clarificationDateWatcher
      );
    },
    evaluationDateWatcher() {
      this.editedItem.evaluation_end_date = this.formatDate(
        this.evaluationDateWatcher
      );
    },
    approvalDateWatcher() {
      this.editedItem.tender_approval_date = this.formatDate(
        this.approvalDateWatcher
      );
    },
  },
  created() {
    this.index();
    this.getAllRequiredDocuments();
    this.getAllTenders();
    this.getCategories();
    this.getAllRequiredDocuments();
    this.getAllTechnicalDocuments();
    this.getAllFinancialDocuments();
  },
  methods: {
    inviteSupplier() {
      this.supplier_modal = false;
      this.$store.dispatch("alert/success", "Supplier invited successfully!");
    },
    continueToApproval() {},
    goToApprovals() {
      this.$router.push("/authority-approvals");
    },
    selectSupplier(item) {
      // console.log(item);
      // var sendConfirm = confirm("Confirm supplier selection?");
      // if (sendConfirm) {
      localStorage.setItem("supplier", JSON.stringify(item));
      this.goToApprovals();
      // }
    },

    newSupplierModal() {
      this.supplier_modal = true;
    },
    truncateText(word, len) {
      return word.substring(0, len) + "...";
    },
    gotToSupplierPrequalification() {
      this.$router.push("/supplier-prequalifications");
    },
    goToEvaluation() {
      this.$router.push("/evaluation");
    },
    gotToPreApproval() {
      this.$router.push("/requisition");
    },
    gotToContract() {
      this.$router.push("/contracts");
    },
    gotToAuthorityApprovals() {
      this.$router.push("/authority-approvals");
    },
    openDocument(item) {
      console.log(item);
      this.$router.replace(item.attachment);
    },
    handleLogout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    index() {
      return SupplierCategoryService.index().then(
        (response) => {
          if (response.status == 200) {
            this.items = response.data.data;
            this.loading = false;
          } else {
            this.items = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    viewItem(item) {
      this.$router.push("/suppliers-view/" + item.id + "/" + item.title);
    },
    deleteItem(item) {
      const index = this.items.indexOf(item);
      var deleteUser = confirm("Are you sure you want to delete this item?");
      if (deleteUser) {
        SupplierCategoryService.delete(item).then(
          (response) => {
            if (response.status == 200) {
              this.$store.dispatch("alert/success", response.data.message);
              this.items.splice(index, 1);
              this.close();
            } else {
              this.$store.dispatch("alert/error", "Error deleting document");
            }
          },
          (error) => {
            if (error.response.status == 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
            } else if (error.response.status == 500) {
              this.$store.dispatch("alert/error", error.response.data.message);
            } else if (error.response.status == 502) {
              this.$store.dispatch("alert/error", error.response.data.message);
            } else if (error.response.status == 401) {
              this.handleLogout();
            } else {
              this.$store.dispatch("alert/error", error.response.statusText);
            }
          }
        );
      }
    },
    close() {
      this.dialog = false;
      this.supplier_modal = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async handleFileUpload(file) {
      console.log("uploading...");
      await this.getBase64(file).then(
        (data) => (this.editedItem.attachment = data)
      );
      //   this.$tender_service.addDocument(formData).then(
      //     (response) => {
      //       console.log(response);
      //       if (response.status == 200) {
      //         this.$store.dispatch("alert/success", "Tender Document uploaded!");
      //         this.getTender();
      //         this.loading = false;
      //       } else {
      //         this.$store.dispatch("alert/error", response.message);
      //         this.loading = false;
      //       }
      //     },
      //     (error) => {
      //       this.loading = false;
      //       if (error.response.status == 422) {
      //         console.log(error.response.data.errors);
      //         this.message = error.response.data.message;
      //       } else {
      //         this.message =
      //           (error.response && error.response.data) ||
      //           error.message ||
      //           error.toString() ||
      //           "Internal Server Error";
      //       }
      //       this.$store.dispatch("alert/error", this.message);
      //       this.loading = false;
      //     }
      //   );
    },

    save() {
      this.loading = true;
      this.$refs.form.validate();
      if (this.valid) {
        // edit
        if (this.editedIndex > -1) {
          Object.assign(this.items[this.editedIndex], this.editedItem);
          try {
            if (this.editedItem.required_documents[0].id) {
              this.editedItem.required_documents =
                this.editedItem.required_document_ids;
            }
          } catch (error) {
            console.log(error);
          }
          console.log(this.editedItem);
          SupplierCategoryService.update(this.editedItem).then(
            (response) => {
              if (response.status == 200) {
                this.$store.dispatch("alert/success", response.data.message);
                this.loading = false;
                this.index();
                this.close();
              } else {
                this.$store.dispatch("alert/error", response.data.message);
                this.index();
                this.loading = false;
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
                this.loading = false;
                this.index();
              }
              if (error.response.status == 401) {
                this.handleLogout();
                this.loading = false;
                this.index();
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        } else {
          // create
          SupplierCategoryService.store(this.editedItem).then(
            (response) => {
              if (response.status == 200) {
                this.items.push(this.editedItem);
                this.$store.dispatch(
                  "alert/success",
                  "Item created successfully"
                );
                this.loading = false;
                this.index();
                this.close();
              } else {
                this.$store.dispatch("alert/error", response.data.message);
                this.index();
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
                this.loading = false;
                this.index();
              }
              if (error.response.status == 401) {
                this.handleLogout();
                this.loading = false;
                this.index();
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        }
      } else {
        this.loading = false;
        this.index();
      }
    },
    // tenders methods

    saveTender() {
      this.$refs.form.validate();
      if (this.valid) {
        this.loading = true;
        this.setDBDateFormat();
        // create
        TenderService.storeTender(this.editedItem).then(
          (response) => {
            if (response.status == 200) {
              this.tenders.push(this.editedItem);
              this.$store.dispatch(
                "alert/success",
                "Tender created successfully"
              );
              this.getAllTenders();
              this.loading = false;
              this.close();
            } else {
              this.revertDBDateFormat();
              this.$store.dispatch(
                "alert/error",
                response.response.statusText || "Internal error!"
              );
              this.loading = false;
            }
          },
          (error) => {
            this.revertDBDateFormat();
            this.loading = false;
            console.log(error);
            if (error.response.status == 422) {
              this.$store.dispatch("alert/error", error);
            }
            this.$store.dispatch("alert/error", error.response.data.message);
          }
        );
      }
    },
    setDBDateFormat() {
      this.editedItem.closing_date = this.reverseFormatDate(
        this.editedItem.closing_date
      );
      this.editedItem.clarification_end_date = this.reverseFormatDate(
        this.editedItem.clarification_end_date
      );
      this.editedItem.evaluation_end_date = this.reverseFormatDate(
        this.editedItem.evaluation_end_date
      );
      this.editedItem.tender_approval_date = this.reverseFormatDate(
        this.editedItem.tender_approval_date
      );
    },
    revertDBDateFormat() {
      // Set date formt
      this.editedItem.closing_date = this.formatDate(
        this.editedItem.closing_date
      );
      this.editedItem.clarification_end_date = this.formatDate(
        this.editedItem.clarification_end_date
      );
      this.editedItem.evaluation_end_date = this.formatDate(
        this.editedItem.evaluation_end_date
      );
      this.editedItem.tender_approval_date = this.formatDate(
        this.editedItem.tender_approval_date
      );
    },
    filterText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },
    filterTender(value, searchTender) {
      return (
        value != null &&
        searchTender != null &&
        typeof value === "string" &&
        value.toString().toLocaleLowerCase().indexOf(searchTender) !== -1
      );
    },
    // required docs
    toggleRequired() {
      this.$nextTick(() => {
        if (this.likesAllRequiredDocument) {
          this.editedItem.required_documents = [];
        } else {
          this.editedItem.required_documents = this.required_documents.slice();
        }
      });
    },
    getAllRequiredDocuments() {
      return TenderService.getAllRequiredDocuments().then(
        (response) => {
          if (response.status == 200) {
            this.required_documents = response.data.data;
          } else {
            this.tenders = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.tenders = [];
          console.log(error);
        }
      );
    },
    // technical docs
    toggleTechnical() {
      this.$nextTick(() => {
        if (this.likesAllTechnicalDocument) {
          this.editedItem.technical_documents = [];
        } else {
          this.editedItem.technical_documents =
            this.technical_documents.slice();
        }
      });
    },
    getAllTechnicalDocuments() {
      return TenderService.getAllTechnicalDocuments().then(
        (response) => {
          if (response.status == 200) {
            this.technical_documents = response.data.data;
          } else {
            this.tenders = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.tenders = [];
          console.log(error);
        }
      );
    },
    // financial docs
    toggleFinancial() {
      this.$nextTick(() => {
        if (this.likesAllFinancialDocument) {
          this.editedItem.financial_documents = [];
        } else {
          this.editedItem.financial_documents =
            this.financial_documents.slice();
        }
      });
    },
    getAllFinancialDocuments() {
      return TenderService.getAllFinancialDocuments().then(
        (response) => {
          if (response.status == 200) {
            this.financial_documents = response.data.data;
          } else {
            this.tenders = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.tenders = [];
          console.log(error);
        }
      );
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    reverseFormatDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("-");
      console.log(`${year}-${month}-${day}`);
      return `${year}-${month}-${day}`;
    },
    getCompanyName(company) {
      return company.company_name;
    },
    tenderPreview(item) {
      this.$router.push("/tender-preview/" + item.id);
    },
    clarificationPreview(item) {
      this.$router.push("/clarification/" + item.id);
    },
    emptyClarificationPreview() {
      alert("No clarification requested!");
    },
    getCategories() {
      return TenderService.getCategories().then(
        (response) => {
          if (response.status == 200) {
            this.tenderCategories = response.data.data;
          } else {
            this.tenders = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.tenders = [];
          console.log(error);
        }
      );
    },
    getAllTenders() {
      return TenderService.getAllTenders().then(
        (response) => {
          if (response.status == 200) {
            this.tenders = response.data.data;
            this.loading = false;
          } else {
            this.tenders = [];
            this.loading = false;
            console.log(response.data.error);
          }
        },
        (error) => {
          this.tenders = [];
          console.log(error);
        }
      );
    },
    editTender(item) {
      this.editedIndex = this.tenders.indexOf(item);
      this.editedItem = Object.assign({}, item);
      // Format date
      this.dialog = true;
    },
    deleteTender(item) {
      const index = this.tenders.indexOf(item);
      var deleteUser = confirm("Are you sure you want to delete this item?");
      if (deleteUser && this.tenders.splice(index, 1)) {
        TenderService.deleteTender(item).then(
          (response) => {
            if (response.status == 200) {
              this.$store.dispatch("alert/success", response.data.message);
              this.close();
            } else {
              this.$store.dispatch("alert/error", response.data.message);
            }
          },
          (error) => {
            if (error.response.status == 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
            }
            console.log(error);
            this.$store.dispatch("alert/error", error.response.data.message);
          }
        );
      }
    },
  },
};
</script>
